@import './vars';

.navbar {
    background-color: $gtt-black;
    margin: 1rem 0;
}

.navbar-brand svg {
    margin-right: 0.5rem;
}

.Footer {
    color: $gtt-mid-grey;
    margin: 0.75em 0;
    text-align: center;
}

.jumbotron h1 {
    text-align: center;
}

.card {
    margin-bottom: 1rem;
}

.btn {
    white-space: nowrap;
}

.alert svg,
.btn svg {
    margin-right: 0.5em;
}

.CpeCard .card-header .dropdown,
.InterfacesCard .card-header .btn {
    margin: -0.45rem -0.5rem;
}

.InterfacesCard .card-footer .btn {
    margin-right: -0.5rem;
}

.table td.PingCell {
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
}

.PingCell .btn {
    width: 100%;
}

.PingResults {
    background-color: $gtt-black;
    padding: 0.75rem;

    pre {
        color: $gtt-green;
        margin-bottom: 0;
        white-space: pre-wrap;
    }
}

@media (max-width: 575.98px) {
    .table td.PingCell {
        padding-top: 0.9rem;
    }
}
