// GTT colors
$gtt-white: rgb(255, 255, 255);
$gtt-black: rgb(0, 0, 0);
$gtt-green: rgb(78, 216, 105);
$gtt-charcoal: rgb(36, 39, 41);
$gtt-dark-grey: rgb(70, 74, 78);
$gtt-mid-grey: rgb(102, 108, 112);
$gtt-light-grey: rgb(194, 198, 201);
$gtt-subtle-grey: rgb(235, 236, 238);
$gtt-light-green: rgb(153, 225, 157);
$gtt-jade: rgb(0, 200, 160);
$gtt-purple: rgb(206, 113, 250);
$gtt-violet: rgb(130, 80, 255);
$gtt-khaki: rgb(150, 155, 120);
$gtt-rich-blue: rgb(25, 125, 255);
$gtt-sky-blue: rgb(80, 195, 255);
$gtt-salmon-pink: rgb(250, 110, 125);
$gtt-peach: rgb(255, 153, 110);

// Import Bootstrap functions (manipulate colors, SVGs, calc, etc)
@import 'bootstrap/scss/functions';

// Override default values
$alert-bg-scale: -30%;
$alert-border-scale: 0%;
$alert-color-scale: 80%;
$enable-gradients: true;
$enable-negative-margins: true;
$enable-shadows: true;
$font-family-sans-serif: Arial, sans-serif;
$font-size-root: 13px;
$input-border-color: $gtt-light-grey;
$input-group-addon-bg: $gtt-subtle-grey;
$input-group-addon-color: $gtt-dark-grey;
$link-color: shade-color($gtt-green, 50%);
$link-decoration: none;
$link-hover-decoration: underline;
$min-contrast-ratio: 3;
$table-color: $gtt-dark-grey;

// Override default theme colors
$primary: $gtt-rich-blue;
$secondary: $gtt-mid-grey;
$success: $gtt-jade;
$info: $gtt-sky-blue;
$warning: $gtt-peach;
$danger: $gtt-salmon-pink;
$light: $gtt-subtle-grey;
$dark: $gtt-black;

// Load all the bootstrap variables
@import 'bootstrap/scss/variables';

// Add some more theme colors

$custom-theme-colors: (
    'gtt': $gtt-green,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);

// Now import the generated maps and mixins
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
